//Accessibility mixins
@mixin focus-outline(){
    outline:$uwkc-blue dotted 2px;
}
@mixin focus-outline-reversed(){
    outline:$uwkc-white dotted 2px;
}


//Breakpoints
$breakpoints: (
  "minimum":      0px,
  "phone":        400px,
  "phone-wide":   480px,
  "phablet":      560px,
  "tablet-small": 640px,
  "tablet":       768px,
  "tablet-large": 781px,
  "tablet-wide":  991px,
  "uwkc-max":     1200px,
  "desktop":      1200px,
  "desktop-wide": 1440px
);


// UWKC colors

$uwkc-orange: #F57814;
$uwkc-orange-hover: #ffaa00;
$uwkc-blue: #1b3a7a;
$uwkc-blue-light: #768ec5;
$uwkc-blue-light-bg: #b7c4e1;
$uwkc-black: #333;
$uwkc-red: #ec4d30;
$uwkc-a11y-red:darken($uwkc-red,15%);
$uwkc-grey: #3d4045;
$uwkc-grey-light: #bababa;
$uwkc-grey-med:darken($uwkc-grey-light,25%);
$uwkc-white: #fff;
$uwkc-gold: #f7b334;
$drop-shadow:1px 1px 1px rgba(0,0,0,0.5);

// UWKC custom color palette for Gutenberg blocks
// -- adjust color palette in /gutenberg/extendor.php
$uwkc-colors: (
  'uwkc-orange': $uwkc-orange,
  'uwkc-blue': $uwkc-blue,
  'uwkc-blue-light': $uwkc-blue-light,
  'uwkc-blue-light-bg': $uwkc-blue-light-bg,
  'uwkc-red': $uwkc-red,
  'uwkc-black' : $uwkc-black,
  'uwkc-gray':$uwkc-grey,
  'uwkc-gray-light':$uwkc-grey-light,
  'uwkc-white': $uwkc-white,
  'uwkc-gold': $uwkc-gold
);

//fluid type mixin

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin fluid-width($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      width: $min-font-size;
      @media screen and (min-width: $min-vw) {
        width: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        width: $max-font-size;
      }
    }
  }
}

// breakpoint media query mixin

@mixin mq($width, $type: 'max') {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
  }

  @if $type == 'min' {
    $width: $width + 1px;
  }

  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

// placeholder styling

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

// vendor prefixer

@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

// z-index tracking

@function z($name) {
    @if index($z-indexes, $name) {
        @return (length($z-indexes) - index($z-indexes, $name)) + 1;
    } @else {
        @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
        @return null;
    }
}
$z-indexes: (
    "outdated-browser",
    "modal",
    "site-header",
    "page-wrapper",
    "site-footer"
);

// Clearfix. Use by extending the rules to any class you want to appy it to.
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// Vendor Prefix
@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

@mixin imageLine($color, $size, $break) {
  position: relative;
  padding-top: $size;

  &:before {
    content: "";
    height: $size;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $color;
    @include vendor-prefix(transition, (all ease .3s));
    @include vendor-prefix(transform, (scaleX(1)));
  }

  @include mq($break, min) {
    
    &:before {
      visibility: hidden;
      @include vendor-prefix(transform, (scaleX(0)));
    }

    &:hover {
      &:before {
        visibility: visible;
        @include vendor-prefix(transform, (scaleX(1)));
      }
    }

  }
}

@mixin funkyLink ($color, $direction: 'middle') {
  position: relative;
  padding-bottom: 2px;

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color;
    visibility: hidden;
    @include vendor-prefix(transition, (all ease .3s));
    @include vendor-prefix(transform, (scaleX(0)));

    @if $direction == 'left' {
      transform-origin: left;
    }

    @if $direction == 'right' {
      transform-origin: right;
    }
  }

  &:hover {
    &:after {
      visibility: visible;
      @include vendor-prefix(transform, (scaleX(1)));
    }
  }
}

//---------------------
// Typography Variables
//---------------------

$font-TG: 'Trade Gothic Next LT W01 Cond', 'Trade Gothic W01 Cn_18', sans-serif;
$font-TG-bold: 'Trade Gothic Next LT W01 Bd Cn', 'TradeGothicW01-BoldCn20_675334', 'Arial', sans-serif;
$font-Meta: 'Meta Offc W01 Normal', 'Arial', sans-serif;
$font-Meta-light:'Meta W01 Light', 'Arial', sans-serif;
$font-Meta-bold: 'Meta W01 Bold', 'Arial', sans-serif;

$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;
$small-text: 90%;
$ex-sm-text:80%;


//Set min and max width for fluid type
$min-width: 320px;
$max-width: 1200px;

// Set min and max base font size and ratio for modular scale
//Read: https://www.rawkblog.com/2018/05/modular-scale-typography-with-css-variables-and-sass/
$max-font-size: 20px;
$max-ratio: 1.30;
$min-font-size: 18px;
$min-ratio:1.1;

//Caluculate min font values to use in fluid-type
$min-h6: $min-font-size;
$min-h5: $min-font-size * $min-ratio;
$min-h4: $min-h5 * $min-ratio;
$min-h3: $min-h4 * $min-ratio;
$min-h2: $min-h3 * $min-ratio;
$min-h1: $min-h2 * $min-ratio;

//Caluculate max font values to use in fluid-type
$max-h6: $max-font-size;
$max-h5: $max-font-size * $max-ratio;
$max-h4: $max-h5 * $max-ratio;
$max-h3: $max-h4 * $max-ratio;
$max-h2: $max-h3 * $max-ratio;
$max-h1: $max-h2 * $max-ratio;

//---------------------
// Typography Mixins
//---------------------


//text shadow
@mixin text-shadow($color){
    text-shadow: 1px 1px 2px $color;
}

//set font sizes
@mixin h1(){
  @include fluid-type($min-width, $max-width, $min-h1, $max-h1); 
}
@mixin h2(){
  @include fluid-type($min-width, $max-width, $min-h2, $max-h2); 
}
@mixin h3(){
  @include fluid-type($min-width, $max-width, $min-h3, $max-h3); 
}
@mixin h4(){
  @include fluid-type($min-width, $max-width, $min-h4, $max-h4); 
}
@mixin h5(){
  @include fluid-type($min-width, $max-width, $min-h5, $max-h5); 
}
@mixin h6(){
  @include fluid-type($min-width, $max-width, $min-h6, $max-h6); 
}

@mixin normal(){
  @include fluid-type($min-width, $max-width, $min-font-size, $max-font-size);
}