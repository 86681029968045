@import "../../mixins";

.latest_posts {
  &_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    justify-content: space-between;

    .overlay {
      opacity: 0.9;
      transition: opacity ease 0.3s;
      pointer-events: none;
    }

    .tns-controls {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      z-index: 2;
      pointer-events: none;
      display: flex;
      justify-content: space-between;

      button {
        pointer-events: auto;
        color: #fff;
        background: none;
        border: none;
        @include fluid-type(400px, 991px, 20px, 26px);
      }
    }
  }

  &_main {
    &_wrapper {
      width: 100%;
      display: block;
      margin-top: 1rem;
      border-radius: 10px;
      position: relative;

      @include mq(tablet-small, min) {
        width: calc(50% - 1rem);
      }

      .latest_posts_link {
        align-items: center;
        justify-content: center;
      }

      .latest_posts_title {
        text-align: center;
      }
    }
  }

  &_post {
    aspect-ratio: 1;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  &_title {
    position: relative;
    z-index: 1;
    margin: 0;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    padding: 2rem;
  }

  &_image {
    position: absolute;
    pointer-events: none;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
      height: 100%;
    }
  }

  &_link {
    width: 100%;
    height: 100%;
    display: flex;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      .overlay {
        opacity: 0.6;
      }
    }
  }

  &_grid {
    &_wrapper {
      width: 100%;

      @include mq(tablet-small, min) {
        width: calc(50% - 1rem);
        justify-content: space-between;
      }
    }

    &_container {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1rem;
      margin-right: -1rem;

      .latest_posts_post {
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        margin: 1rem;

        @include mq(tablet-small, min) {
          width: calc(50% - 2rem);
          .latest_posts_title {
            font-size: 2.2rem;
          }
        }
      }

      .latest_posts_link {
        align-items: center;
        justify-content: center;

        @include mq(tablet-small, min) {
          align-items: flex-end;
          justify-content: flex-end;
        }
      }

      .latest_posts_title {
        text-align: center;

        @include mq(tablet-small, min) {
          text-align: right;
        }
      }
    }
  }
}

.page_section_wrapper {
  .latest_posts_container {
    margin-top: -3rem;

    .latest_posts_grid_wrapper {
      .latest_posts_title {
        @include mq(tablet-small, min) {
          font-size: 1.8rem;
        }
      }
    }
  }
}
